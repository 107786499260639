* {
    box-sizing: border-box;
}

body {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    background-color: #F0F1F7;
    margin: 0;
}

.container {
    max-width: 777px;
    margin-left: auto;
    margin-right: auto;
}

.w-full {
    width: 100%;
}

.container-sm {
    width: 450px;
    margin-left: auto;
    margin-right: auto;
}

.box {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #D6DDEB;
}

.close-icon {
    cursor: pointer;
}

.csat-question {
    margin: 25px 0;
}

.csat-question-label {
    color: #111C43;
    font-size: 14px;
    font-weight: 700;
    font-family: "Lato", sans-serif;
}

.divider {
    border-top: 1px dashed #D6DDEB;
    margin: 15px 0 25px;
    opacity: 1;
}

.csat-form-content-header {
    display: flex;
    flex-direction: column;
}

.rating-meter-legends {
    display: flex;
    justify-content: space-between;
    max-width: 580px;
    margin: 0 auto;
}

.form-check {
    position: relative;
    width: 58px;
    height: 36px;
    border: 1px solid #D6DDEB;
    background-color: #F5F5F5;
}

.form-check:nth-child(1) input:hover+span,
.form-check:nth-child(2) input:hover+span,
.form-check:nth-child(3) input:hover+span,
.form-check:nth-child(4) input:hover+span,
.form-check:nth-child(5) input:hover+span,
.form-check:nth-child(6) input:hover+span {
    background-color: #D70823;
}

/* .form-check:nth-child(7),
.form-check:nth-child(8) {
    background-color: #FBF0DA;
} */

.form-check:nth-child(7) input:hover+span,
.form-check:nth-child(8) input:hover+span {
    background-color: #EFB700;
}

/* .form-check:nth-child(9),
.form-check:nth-child(10) {
    background-color: #D6F0E0;
} */

.form-check:nth-child(9) input:hover+span,
.form-check:nth-child(10) input:hover+span {
    background-color: #15B13A;
}

.form-check-wrapper {
    display: flex;
    margin: 10px 0;
    justify-content: center;
}

.form-check input {
    opacity: 0;
    margin: 0;
    position: relative;
    z-index: 1;
    width: 58px;
    height: 36px;
    cursor: pointer;
}

.form-check span {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .form-group {
    margin-bottom: 20px;
} */

.form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #D6DDEB;
    border-radius: 6px;
    margin-bottom: 4px;
}

.form-control:focus {
    outline: none;
}

textarea {
    min-height: 130px;
    resize: none;
}

.btn-primary {
    background-color: #2557D6;
    border-radius: 7px;
    padding: 10px 40px;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    font-family: "Lato", sans-serif;
}

.save-img {
    width: 18px;
    margin-right: 10px;
}

.btn-primary:hover {
    opacity: 0.8;
}

.btn-primary:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.text-danger {
    color: #f00;
}

.csat-form-buttons {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.logo {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.logo img {
    width: 200px;
}

.csat-form-content .row {
    display: flex;
    gap: 20px;
}

.csat-form-content .row .form-group {
    flex: 1;
}

.client-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.client-feedback {
    margin: 20px 0;
}

.textarea {
    font-family: "Lato", sans-serif;
    font-size: 13px;
    resize: none;
}

.floating-label-content {
    position: relative;
}

.floating-label {
    color: #838383;
    font-size: 13px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 11px;
    padding: 0;
    background: #fff;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.floating-input:focus {
    outline: none;
}

.floating-input:focus~.floating-label {
    top: -8px;
    font-size: 13px;
}

.floating-input:not(:placeholder-shown)~.floating-label {
    top: -8px;
    font-size: 13px;
}

.floating-select:not([value=""]):valid~.floating-label {
    top: -8px;
    font-size: 13px;
}

.floating-select[value=""]:focus~.floating-label {
    top: 11px;
    font-size: 13px;
}

.form-check.green {
    background-color: #15B13A;
    color: #ffffff;
}

.form-check.yellow {
    background-color: #EFB700;
    color: #ffffff;
}

.form-check.red {
    background-color: #D70823;
    color: #ffffff;
}

.sucess-box {
    border: 1px solid #4CD56D;
    border-radius: 50px;
    width: 100%;
    padding: 50px;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title-text {
    font-size: 35px;
    color: #000;
    font-weight: bold;
    margin-bottom: 15px;
}

.sub-text {
    font-size: 16px;
    color: #838383;
}

.thank-you-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid green;
    margin-top: 20px;
    color: green;
}

.check-mark {
    width: 50px;
    height: 50px;
    margin-bottom: 2%;
}

.d-inline-block {
    display: inline-block;
}